/* eslint-disable max-len */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { jlocation_name_code } from '../../interfaces/jlocation.interface';
import { HelperService } from '../../services/helper.service';
import { JSettings, SupaService } from '../../services/supa.service';
import { debounceTime } from 'rxjs/operators';
import { IframeWrapperComponent } from '../../components/iframewrapper/iframewrapper.component';
import { OSBSearchDialog } from './osbsearch/osbsearch.component';
import { Vehicle } from '../../interfaces/vehicle.interface';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { DmsService } from '../../services/dms.service';
import { environment } from '../../../environments/environment';
import { GeminisearchDialog } from './geminisearch/geminisearch.component';
import { OSBWizardDialog } from './osbwizard/osbwizard.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild('strokeGroup') strokeGroup;

  loadingComplete=false;
  onlyMyItems=false;
  jlocations:   jlocation_name_code[]|undefined = undefined;
  newRecords:   VhcRecordHomeCard[] = [];
  preRecords:   VhcRecordHomeCard[] = [];
  mpiRecords:   VhcRecordHomeCard[] = [];
  jpRecords:    VhcRecordHomeCard[] = [];
  authRecords:  VhcRecordHomeCard[] = [];
  qiRecords:    VhcRecordHomeCard[] = [];
  finRecords:   VhcRecordHomeCard[] = [];

  jlocation_codes: string[] = [];

  helpurl='https://www.youtube.com/embed/kPZH75Zja50';

  dataSubscription: Subscription;
  timerSubscription: Subscription;
  getNewData = new Subject();

  exconfig = this.supa.ExternalConfig();

  constructor(private helper: HelperService,
    private router: Router,
    private supa: SupaService,
    private loadingController: LoadingController,
    private dms: DmsService) {
  }

  ngOnInit() {
    this.supa.supabase.from<jlocation_name_code>('jlocation').select('code,name').then(loc => {
      if (loc.error) {
        this.helper.notify('Could not load locations', 'danger');
      } else {
        if (this.supa.LoginObject.value?.permissions.includes('VhcAllLocations')) {
          this.jlocations = loc.data;
        } else {
          this.jlocations = loc.data.filter(d => this.supa.LoginObject.value?.permissions.includes('Location'+d.code));
        }
        this.getNewData.next();
      }
    });
    this.dataSubscription = this.getNewData.pipe(debounceTime(500)).subscribe(v => {this.loadData();});
    this.timerSubscription = timer(60000,60000).subscribe(v => {this.getNewData.next();});
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  selectedLocations(_: any) {
    this.getNewData.next();
  }

  getLocationFilter() {
    let locationFilter = this.jlocation_codes;
    if (locationFilter.length == 0) {
      locationFilter = (this.jlocations ? this.jlocations : []).map(l => l.code);
      locationFilter.push('');
    }
    return locationFilter;
  }

  filterText = ''
  
  filterTextUpdated() {
    this.getNewData.next();
  }
  
  recordIsFiltered(record: VhcRecordHomeCard){
    console.log('gere');
    return (record.cartype?.toLowerCase().indexOf(this.filterText)>-1
            ||record.iqjc_id.split('-').pop()?.toLowerCase().indexOf(this.filterText)>-1
            ||record.jlocation_code?.toLowerCase().indexOf(this.filterText)>-1
            ||record.licenseplate?.toLowerCase().indexOf(this.filterText)>-1);
  }

  async loadData() {
    console.log('loading data');

    this.loadingComplete = false;
    const exconf = await this.supa.ExternalConfig();
    const locationFilter = this.getLocationFilter();
    const settings = await this.supa.Settings();

    const _newRecords: VhcRecordHomeCard[] = [];
    const _preRecords: VhcRecordHomeCard[] = [];
    const _mpiRecords: VhcRecordHomeCard[] = [];
    const _jpRecords: VhcRecordHomeCard[] = [];
    const _authRecords: VhcRecordHomeCard[] = [];
    const _qiRecords: VhcRecordHomeCard[] = [];
    const _finRecords: VhcRecordHomeCard[] = [];
    this.supa.supabase.rpc('get_home_data', {param: locationFilter, onlyme: (this.onlyMyItems ? this.supa.LoginObject.value?.id : '%%')}).then(v => {
      if (v.error) {
        this.helper.notify('Could not load the records', 'danger');
      } else {
        const now = DateTime.local();
        (v.data as VhcRecordHomeCard[]).filter(rec => this.filterText.length===0||this.recordIsFiltered(rec)).forEach(rec => {
          switch (rec.status) {
            case 'new':
              _newRecords.push(rec);
              break;
            case 'pre':
              rec.stale = DateTime.fromISO(rec.created_at).plus({minutes: settings.maxtimepre}) < now;
              _preRecords.push(rec);
              break;
            case 'mpi':
              rec.stale = DateTime.fromISO(rec.completedpreauth).plus({minutes: settings.maxtimempi}) < now;
              _mpiRecords.push(rec);
              break;
            case 'jp':
              rec.stale = DateTime.fromISO(rec.completedprejp != null ? rec.completedmpi : rec.completedpre).plus({minutes: rec.completedprejp != null ? settings.maxtimejp : settings.maxtimeprejp}) < now;
              _jpRecords.push(rec);
              break;
            case 'auth':
              rec.stale = DateTime.fromISO(rec.completedpreauth != null ? rec.completedjp : rec.completedprejp).plus({minutes: rec.completedpreauth != null ? settings.maxtimeauth : settings.maxtimepreauth}) < now;
              _authRecords.push(rec);
              break;
            case 'qi':
              rec.stale = DateTime.fromISO(rec.completedauth).plus({minutes: settings.maxtimeqi}) < now;
              _qiRecords.push(rec);
              break;
            default:
              rec.stale = false;
              _finRecords.push(rec);
              break;
          }
        });
        this.newRecords = _newRecords.sort(a => -a.stale);
        this.preRecords = _preRecords.sort(a => -a.stale);
        this.mpiRecords = _mpiRecords.sort(a => -a.stale);
        this.jpRecords = _jpRecords.sort(a => -a.stale);
        this.authRecords = _authRecords.sort(a => -a.stale);
        this.qiRecords = _qiRecords.sort(a => -a.stale);
        this.finRecords = _finRecords.sort(a => -a.stale);

        // Only some configurations allow OSB appointments
        if (exconf.name !== 'Gemini') {
          // osb search starts
          this.supa.supabase.rpc('relevant_osb_appointment', {locations: this.getLocationFilter().join(','), fromtime: Math.round(this.dms.GetNowTime()-180000).toString(), tilltime: Math.round(this.dms.GetNowTime()+180000).toString()}).then(g => {
            if (g.error) {
              this.helper.notify('Could not load the osb records', 'danger');
              this.loadingComplete = true;
            } else {
              const _newRecords: VhcRecordHomeCard[] = [];
              g.data.forEach(rec => {
                _newRecords.push({
                  id: rec.id,
                  assignees: '',
                  cartype: rec.customerName,
                  licenseplate: rec.licensePlate,
                  status: rec.arrivalTime + '',
                  jlocation_code: rec.locationCode,
                  vin: rec.vin,
                  portalstatus: 0,
                  osbtime: rec.arrivalTime,
                  iqjc_id: rec.iqjc_id,
                  stale: false
                });
              });
              this.newRecords = _newRecords.filter(rec => this.filterText.length===0||this.recordIsFiltered(rec));
              this.loadingComplete = true;
            }
          });
          // osb search ended
        } else {
          // the config has no osb
          this.loadingComplete = true;
        }
      }
    });
  }

  @ConfirmDelete('Update vehicle and create VHC Record?', 'New VHC Record')
  async synchVehicleAndOpenRecord(apptid,vin) {
    const loading = await this.loadingController.create({
      message: 'Retrieving data from OSB',
      spinner: 'bubbles',
      backdropDismiss: true
    });
    await loading.present();

    const countvehicleresult = await this.supa.supabase.from('vehicle').select('*', { count: 'exact' }).eq('vin', vin);
    const appointmentresult = await this.supa.supabase.rpc<OsbAppointment>('get_osb_appointment', {id:apptid}).single();
    console.log(countvehicleresult,appointmentresult);
    const vehicle: Vehicle = {
      vin: appointmentresult.data.vin,
      licenseplate: appointmentresult.data.licensePlate,
      cartype: appointmentresult.data.model,
      drivername: appointmentresult.data.customerName,
      drivermail: appointmentresult.data.customerEmail,
      driverphone: appointmentresult.data.phoneNumber,
      ownername: appointmentresult.data.customerName,
      ownermail: appointmentresult.data.customerEmail,
      ownerphone: appointmentresult.data.phoneNumber,
    };
    const errorUpsert = false;
    if (countvehicleresult.count == 0) {
      loading.message = 'Creating vehicle';
      const createvehicleresult = await this.supa.supabase.from('vehicle').insert(vehicle, { returning: 'minimal' });
    } else {
      loading.message = 'Updating vehicle';
      const updatevehicleresult = await this.supa.supabase.from('vehicle').update(vehicle, { returning: 'minimal' }).eq('vin', vin);
    }
    loading.dismiss();
    this.helper.createNewRecord(vin, appointmentresult.data, this.helper, this.supa, true);
  }

  openRecord(id) {
    this.router.navigate(['vhc-record', {id}]);
  }

  openVehicle(vin) {
    this.router.navigate(['vehicles/editor', {vin}]);
  }

  finalizeAllCompleted() {
    this.helper.promptYesNo('Finalize all completed records?', 'Do you want to finalize all vhc records and mark them done?', async () => {
      const loadr = await this.loadingController.create({ duration: 0, animated: true, showBackdrop: true });
      loadr.present();
      this.supa.supabase.from('vhcrecord').update({ isdone: true }, { returning: 'minimal' }).match({
        status: 'fin',
        isdone: false,
        deactivated: false
      }).then(v => {
        loadr.dismiss();
        if (v.error) {
          this.helper.notify('Records could not be finalized', 'danger');
        } else {
          this.helper.notify('Records were finalized', 'success');
          this.finRecords = [];
        }
      });
    }, 'Mark as done', 'Cancel');
  }

  sgMouseIsDown = false;
  sgStartingX = 0;
  sgStartingScrollX = 0;
  setMouseDown(event, isDown: boolean) {
    if (isDown && this.strokeGroup) {
      this.sgStartingX = event.screenX;
      this.sgStartingScrollX = this.strokeGroup.nativeElement.scrollLeft;
      this.sgMouseIsDown = true;
    } else {
      this.sgMouseIsDown = false;
    }
  }

  sgMove(event) {
    if (this.sgMouseIsDown) {
      const deltaScrollX = this.sgStartingX - event.screenX;
      this.strokeGroup.nativeElement.scrollLeft = this.sgStartingScrollX + deltaScrollX;
    }
  }

  alpha=["q","e","r","t","u","i","v","o","p","m"]
  beta=["a","s","y","d","z","f","c","h","k","j"]

  amenc(inp:string) {
    if (inp.length <= 1)
      return this.alpha[Number.parseInt(inp[0])];
    else if (inp.length % 2 == 0)
      return this.alpha[Number.parseInt(inp[0])] + this.amenc(inp.substring(1))
    else
      return this.beta[Number.parseInt(inp[0])] + this.amenc(inp.substring(1))
  }

  osb() {  
    this.helper.createAndOpenModal(IframeWrapperComponent, { url: environment.osbFrontendUrl+"?advancedfeatures="+this.amenc(DateTime.utc().toMillis().toString()), title: 'IQ OSB' }, 'fullscreen-modal');
  }

  osbwizard() {
    this.helper.createAndOpenModal(OSBWizardDialog, {}, 'big-modal');
  }

  async geminisearch() {
    this.helper.createAndOpenModal(GeminisearchDialog, {}, 'big-modal');
  }

  async keyloopsearch() {
    this.helper.promptInput('Search in Keyloop', 'Please enter the WIP', '', async (b)=>{
      const l = await this.helper.getLoader();
      l.message = 'Getting repair-order from Keyloop';
      await l.present();

      const ro = await this.supa.supabase.rpc('find_jc_keyloop', {jcref: b});
      l.dismiss();

      if ((ro.data as any)?.repairOrderId) {
        const jobcard = (ro.data as any);
        const docreate = await this.helper.promptYesNo(jobcard.repairOrderId, jobcard.vehicle.description, ()=>{}, 'Create Record');
        if (docreate) {
          const lo = await this.helper.getLoader();
          lo.message = 'Loading data';
          await lo.present();
          console.log(jobcard);

          const vehicledata: any = {};
          const keyloopvehicle = await this.supa.supabase.rpc('get_vehicle_info', {vin: jobcard.vehicle.vin});
          vehicledata.licenseplate = (keyloopvehicle.body as any).identification.licensePlate;
          vehicledata.cartype = (keyloopvehicle.body as any).baseDetails.description + ' ' + (keyloopvehicle.body as any).baseDetails.exteriorColor;
          vehicledata.enginecode = (keyloopvehicle.body as any).technicalData.engineConcept;
          vehicledata.yearofbuild = (keyloopvehicle.body as any).baseDetails.modelYear;

          const ourvehicle = await this.supa.supabase.from('vehicle').select('vin', {count: 'exact'}).eq('vin', jobcard.vehicle.vin);
          if (ourvehicle.count < 1) {
            await this.supa.supabase.from('vehicle').insert({
              'vin': jobcard.vehicle.vin,
              'licenseplate': jobcard.vehicle.licensePlate,
              'cartype': vehicledata.cartype,
              'enginecode': vehicledata.enginecode,
              'yearofbuild': vehicledata.yearofbuild,
              'communicatewithowner': false,
              'drivername': '',
              'drivermail': '',
              'driverphone': '',
              'ownername': '',
              'ownermail': '',
              'ownerphone': '',
            });
          } else {
            const didupdate = await this.supa.supabase.from('vehicle').update(vehicledata).eq('vin', jobcard.vehicle.vin);
            if (didupdate.error) {
              this.helper.notify("Could not save the vehicle", "danger");
              lo.dismiss();
              return;
            }
          }
          lo.dismiss();

          const now = DateTime.now().toSeconds();
          localStorage.setItem('keylooproid_time', now.toString());
          localStorage.setItem('keylooproid', jobcard.repairOrderId);
          this.helper.createNewRecord(jobcard.vehicle.vin);
        }
        return;
      }
      this.helper.notify('Could not find the jobcard', 'danger');
    });
  }

  async osbsearch() {
    const result = await this.helper.createAndOpenModal(OSBSearchDialog, {locationFilter: this.getLocationFilter().join(',')}, 'big-modal');
    if (result.data) {
      this.synchVehicleAndOpenRecord(result.data.id, result.data.vin);
    }
  }
}

export interface VhcRecordHomeCard {
  id: number;
  status: string;
  licenseplate: string;
  assignees: any;
  cartype: string;
  jlocation_code: string;
  portalstatus: number;
  vin? : string;
  osbtime?: number;
  iqjc_id?: string;
  stale: boolean;

  created_at?: string;
  completedpre?: string;
  completedprejp?: string;
  completedpreauth?: string;
  completedmpi?: string;
  completedjp?: string;
  completedauth?: string;
  completedqi?: string;
}

export interface OsbJob {
  appointmentId: number;
  customerTooltip: string;
  duration: number;
  id: number;
  name: string;
  operationCode: string;
  price: string;
}

export interface OsbAppointment {
  appointmentCancelled: boolean;
  appointmentCompleted: boolean;
  appointmentCompletedLate: boolean;
  arrivalTime: number;
  arrivalTimeChosen: string;
  brand: string;
  cancelDate?: any;
  creationTextSent: number;
  customerAddress?: any;
  customerCompany?: any;
  customerEmail?: any;
  customerName: string;
  customerPostalCode?: any;
  fromCustomer: boolean;
  handoverTime: number;
  hhaAutoRemark?: any;
  hhaNote: string;
  hhaService?: any;
  hhapayload: string;
  id: number;
  jobs: OsbJob[];
  kms?: any;
  language: string;
  licensePlate: string;
  locationCode: string;
  model: string;
  phoneNumber: string;
  receptionist: string;
  reminderTextSent: number;
  seenFolder: boolean;
  vin: string;
  withUser: string;
  dmsId: string;
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Part } from 'src/app/interfaces/part.interface';
import { DmsTask } from 'src/app/services/dms.service';
import { JSettings, SupaService } from 'src/app/services/supa.service';

@Component({
    selector: 'app-dmsdragdrop',
    templateUrl: './dmsdragdrop.dialog.html',
    styleUrls: ['./dmsdragdrop.dialog.scss'],
})
export class DmsDragDropDialog implements OnInit {

    @Input() leftItems: DmsTask[] = [];
    @Input() rightItems: Part[] = [];

    settings: JSettings|undefined = undefined;

    constructor(private supa: SupaService, private modalController: ModalController) {
    }

    dismissModal() {
        this.modalController.dismiss();
    }

    saveModal() {
        this.modalController.dismiss({leftItems: this.leftItems});
    }

    ngOnInit() {
        this.supa.Settings().then(s => {
            this.settings = s;
        });
    }

    allowDrop(event: DragEvent) {
        event.preventDefault();
    }

    onDrag(event: DragEvent, part: Part, index: number, partindex: number) {
        event.dataTransfer.setData('part', JSON.stringify(part));
        event.dataTransfer.setData('index', JSON.stringify(index));
        event.dataTransfer.setData('partindex', JSON.stringify(partindex));
    }

    onDrop(event: DragEvent, targetIndex: number) {
        event.preventDefault();
        const part = event.dataTransfer.getData('part');
        const index = Number.parseInt(event.dataTransfer.getData('index'), 10);
        const partindex = Number.parseInt(event.dataTransfer.getData('partindex'), 10);

        console.log(part, index, partindex, targetIndex);

        if (index < 0) {
            // dragging from rightItems
            this.leftItems[targetIndex].parts.push(this.rightItems.splice(partindex, 1)[0]);
        } else {
            // dragging between leftItems
            this.leftItems[targetIndex].parts.push(this.leftItems[index].parts.splice(partindex, 1)[0]);
        }
    }

    removePart(i: number, pi: number) {
        this.rightItems.push(this.leftItems[i].parts.splice(pi, 1)[0]);
    }
}

import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { VhcDrawModule } from '../vhcdrawmodule';
import { LoadingController, ModalController } from '@ionic/angular';
import { DrawerLibComponent } from 'drawer-lib';
import { HelperService } from '../../../services/helper.service';
import { MediaService } from '../../../services/media.service';

@Component({
  selector: 'app-drawanswer',
  templateUrl: './drawanswer.dialog.html',
  styleUrls: ['./drawanswer.dialog.scss'],
})
export class DrawanswerDialog implements AfterViewInit {

  @ViewChild('drawer', {static: false}) drawer: DrawerLibComponent;

  @Input('drawmodule') drawmodule: VhcDrawModule = null;

  retryCounter = 50;

  constructor(private modalController: ModalController, private helper: HelperService, private loadingController: LoadingController) { }

  ngAfterViewInit() {
    this.retryCounter--;
    if (this.drawer) {
      this.doInitialisation();
    } else if (this.retryCounter > 0) {
      setTimeout(()=>{ this.ngAfterViewInit(); }, 100);
    } else {
      this.helper.notify('Could not load the drawer.', 'danger');
    }
  }

  async doInitialisation() {
    this.drawer.Background.crossOrigin = 'Anonymous';
    this.drawer.Background.src = this.drawmodule.data.background;
    
    const imagesSum = this.drawmodule.data.drawItems.reduce((sum, str) => sum + str.length, 0);
    console.log(imagesSum);
    if (imagesSum >= 6017088) { // about 3 * 2.6 MB photo's in base64 format.
      const loading = await this.loadingController.create({
        message: 'Recucing image quality',
        duration: 2000,
        spinner: 'bubbles'
      });
      await loading.present();
      let olddatacounter = 0;
      let newdatacounter = 0;
      for(let i = 0; i < this.drawmodule.data.drawItems.length; i++) {
        loading.message = 'Reducing filesize, ' + (i+1) + ' of ' + this.drawmodule.data.drawItems.length;
        const thedata = JSON.parse(this.drawmodule.data.drawItems[i]);
        if (thedata.type === 'camera') {
          olddatacounter += thedata.data.image.length;
          thedata.data.image = await MediaService.resizeImage(thedata.data.image);
          newdatacounter += thedata.data.image.length;
          this.drawmodule.data.drawItems[i] = JSON.stringify(thedata);
        }
      }
      
      const newtotal = olddatacounter - newdatacounter;
      this.helper.notify(`Old size was ${Math.round(olddatacounter/10000)/100} MB, new size is ${Math.round(newdatacounter/10000)/100} MB, difference is ${Math.round(newtotal/10000)/100} MB`, 'primary', 7000)
      loading.dismiss();
    }

    this.drawer.Deserialize(this.drawmodule.data.drawItems);
  }

  handleClose() {
    if (this.drawer) {
      this.drawmodule.data.finalDrawing = this.drawer.GetImage();
      this.drawmodule.data.drawItems = this.drawer.Serialize();
      this.drawmodule.data.background = this.drawer.Background.src;
    }
  }

  dismissModal() {
    this.handleClose();
    this.modalController.dismiss(false, 'cancel');
  }

  nextModal() {
    this.handleClose();
    this.modalController.dismiss(true);
  }

}

import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { VideorecorderDialog } from '../components/videorecorder/videorecorder.component';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor
    (
      private toastController: ToastController,
      private modalController: ModalController,
      private loadingController: LoadingController
    ) {
    console.log("INIT M Service");
  }

  public async takeVideo():Promise<string|undefined> {
    let vrecorder = await this.modalController.create({component: VideorecorderDialog, cssClass: 'fullscreen-modal doubled-modal'})
    await vrecorder.present()
    let res = await vrecorder.onDidDismiss();
    if (res) {
      if (res.data) {
        if (res.data.path) {
          return res.data.path
        }
      }
    }
    return undefined
  }

  public static async resizeImage(base64Image:string):Promise<string> {
    const maxWidth = 800;
    const maxHeight = 800;

    return new Promise<string>(async (resolve, reject) => {
      const img = new Image();
      img.src = base64Image;

      img.onload = ()=>{
        const originalWidth = img.width;
        const originalHeight = img.height;
        const aspectRatio = originalWidth / originalHeight;
        
        let newWidth;
        let newHeight;
        if (originalWidth > originalHeight) {
          newWidth = maxWidth;
          newHeight = maxWidth / aspectRatio;
        } else {
          newHeight = maxHeight;
          newWidth = maxHeight * aspectRatio;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        resolve(canvas.toDataURL('image/jpeg', 0.9));
      };
    });
  }

  public takePicture():Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      const loading = await this.loadingController.create({
        message: 'Loading picture',
        spinner: 'bubbles',
      });
      await loading.present();
      Camera.getPhoto({
        quality: 60,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera
      }).then(async (v) => {
        loading.message = 'Resizing the photo to save data.'

        const originalImage = v.dataUrl;
        const scaledImage = await MediaService.resizeImage(v.dataUrl);

        loading.dismiss()
        
        if (originalImage.length < scaledImage.length) {
          console.log('Original image is smaller');
          resolve(originalImage);
        } else {
          console.log('Scaled image is smaller');
          resolve(scaledImage);
        }
      })
      .catch(reason => {
        loading.dismiss()
        resolve('rejected')
      })
    });
  }

}